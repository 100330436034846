
import { useCheckout } from '@/composables/checkout/useCheckout'
import { useNumeral } from '@/composables/useNumeral'
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const {
      isLoaded,
      products,
      useMonthlyValue,
      monthlyValue,
      currencyCode
    } = useCheckout()

    const { format } = useNumeral()
    
    return {
      isLoaded,
      products,
      useMonthlyValue,
      monthlyValue,
      currencyCode,
      format
    }
  }
})
