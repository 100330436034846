import checkoutService from "@/services/checkoutService"
import { computed, ref } from "vue"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import { useNumeral } from "../useNumeral"
import { useToggle } from "../useToggle"
import { UserServices } from "@/services/userService"
import countries from '../../components/base/countries'

type Installment = {
  installment: number
  value: number
}

type CardExpirationDate = {
  expMonth: string
  expYear: string
}

const getDDI = (phoneNumber: string): string | null => {
  // Extrair o código DDI do número de telefone
  const ddiCode = phoneNumber.match(/^\+?(\d{1,3})/)?.[1];

  // Encontrar o país com o código DDI
  const country = countries.find((ddi) => ddi.value === ddiCode);

  // Retornar o país e o código DDI
  return country ? country.value : null;
}

const getCardExpirationDate = (data: string): CardExpirationDate | undefined => {
  const cardExpirationDate = data.split('/')
  const expMonth = cardExpirationDate[0]
  const expYear = cardExpirationDate[1]

  if (!expMonth || !expYear) {
    return undefined
  }
  
  return {
    expMonth,
    expYear
  }
}

export const useCheckout = () => {
  const store = useStore()

  const { format } = useNumeral()

  const router = useRouter()

  const couponErrorMessage = ref('')

  const {
    isActive: isResearchCoupon,
    setActive: startResearchCoupon,
    setInactive: endResearchCoupon
  } = useToggle(false)

  const checkoutFormData = computed(() => {
    return store.state.checkout.checkoutFormData
  })

  const fetchCheckoutInfo = (checkoutId: string) => {
    const isLoaded = store.state.checkout.isLoaded
    if (!isLoaded) {
      store.dispatch('checkout/fetchCheckoutInfo', checkoutId)
    }
  }

  const updateCheckoutFormData = (payload: any) => {
    store.commit('checkout/updateCheckoutFormData', payload)
  }

  const submitCheckoutData = async (profileId?: string) => {
    function splitPhone(phone: string) {
      const countryCode = getDDI(data.phone)

      if (countryCode) {
        const phoneNumber = countryCode && phone.startsWith(countryCode) ? phone.replace(countryCode, '') : phone
        
        if (countryCode === '55') {
          const index = 2
          return [countryCode, phoneNumber.slice(0, index), phoneNumber.slice(index)];
        }
        return [countryCode, null, phoneNumber];
      }
      return [null, null, phone];
    }

    const data = checkoutFormData.value

    const cardExpirationDate = getCardExpirationDate(data.cardExpirationDate)

    const documentType = data.document.replace(/[^0-9]/g,'').length <= 11 ? 'CPF' : 'CNPJ'

    const [countryCode, areaCode, phoneNumber] = splitPhone(data.phone.replace(/[^0-9]/g,''))

    const payload = {
      customer: {
        documentType: data.document.trim().length ? documentType : "",
        document: data.document,
        address: {
            country: 'BR',
            state: data.state,
            city: data.city,
            zipCode: data.zipCode,
            street: data.street,
            number: data.number,
            neighborhood: data.neighborhood,
            complement: data.complement
        },
        phone: {
            countryCode: countryCode ? Number(countryCode) : null,
            areaCode: areaCode ? Number(areaCode) : null,
            number: phoneNumber ? Number(phoneNumber) : null
        },
        name: data.name,
        email: data.email
      },
      ...(data.paymentMethod === 'credit_card' && {creditCard: {
        number: data.cardNumber,
        holderName: data.cardHolderName,
        expMonth: cardExpirationDate?.expMonth,
        expYear: cardExpirationDate?.expYear,
        cvv: data.cardCVV
      }}),
      checkoutId: checkoutCode.value,
      paymentMethod: data.paymentMethod,
      installments: data.installments,
      ...(data.coupon && {coupon: data.coupon}),
      ...(profileId && {
        metadata: {
          profileId: profileId
        }
      })
    }
    // console.log(payload)
    await store.dispatch('checkout/makePurchase', payload)
  }

  const saveLead = async () => {
    const data = checkoutFormData.value

    const areaCodeRe = /(\d{2})/
    const areaCode = areaCodeRe.exec(data.phone)

    const phoneNumberRe = /[^)]*$/
    const phoneNumber = phoneNumberRe.exec(data.phone)

    const payload = {
      checkoutId: checkoutCode.value,
      document: data.document,
      name: data.name,
      email: data.email,
      phone: {
        countryCode: 55,
        areaCode: areaCode ? Number(areaCode[0]) : null,
        number: phoneNumber ? Number(phoneNumber[0].replace(/[^0-9]/g,'')) : null
      }
    }

    await checkoutService.saveLead(payload)
  }

  const researchUserProfiles = async () => {
    // return []
    const data = checkoutFormData.value
    const profiles = await UserServices.researchUserProfiles(data.email)
    return profiles
  }

  const researchCoupon = async () => {
    const coupon = checkoutFormData.value.coupon
    
    if (!coupon) {
      return
    }

    startResearchCoupon()
    couponErrorMessage.value = ''

    try {
      const discount = await checkoutService.researchCoupon(coupon)
      store.commit('checkout/updateDiscount', discount)
    } catch (error) {
      couponErrorMessage.value = 'Código inválido.'
    }

    endResearchCoupon()
  }

  const checkoutCode = computed(() => {
    return router.currentRoute.value.params.checkout_code as string
  })

  const isLoaded = computed(() => {
    return store.state.checkout.isLoaded
  })

  const submitCheckoutStatus = computed(() => {
    return store.state.checkout.submitCheckoutStatus
  })

  const installments = computed(() => {
    const selectedPaymentMethod = store.state.checkout.checkoutFormData.paymentMethod 
    const items = store.state.checkout.installments

    const result = items.map((item: Installment) => {
      return {
        value: item.installment,
        label: `${item.installment} x ${format(total.value / item.installment, '$0.00')}`,
      }
    })

    if (selectedPaymentMethod === 'boleto') {
      return [total.value]
    }

    return result
  })

  const useMonthlyValue = computed(() => {
    return store.state.checkout.installments.length === 1
  })

  const monthlyValue = computed(() => {
    const items = store.state.checkout.installments
    return items[0].value
  })

  const products = computed(() => {
    return store.state.checkout.products
  })

  const currencyCode = computed(() => {
    return products.value[0].currency || 'brl'
  })

  const paymentMethods = computed(() => {
    return store.state.checkout.paymentMethods
  })

  const discount = computed(() => {
    const discount = store.state.checkout.discount

    const hasDiscount = discount !== null
    const discountType = discount?.discountType
    const grossValue = discount?.value

    const total = useMonthlyValue.value ? monthlyValue.value : productsTotal.value
    let netValue = 0

    if (hasDiscount) {
      switch (discountType) {
        case 'percentage':
          netValue = Math.max(0, total * (grossValue / 100))
          break;
        case 'flat':
          netValue = Math.max(0, grossValue)
          break;
        default:
          break;
      }
    }

    const difference = Math.max(0, total - (total - netValue))

    return {
      hasDiscount,
      discountType,
      grossValue,
      netValue,
      difference
    }
  })

  const productsTotal = computed(() => {
    return products.value.reduce((prev: number, curr: {price: number}) => {
      return prev + curr.price
    }, 0)
  })

  const total = computed(() => {
    const total = useMonthlyValue.value ? monthlyValue.value : productsTotal.value
    return total - discount.value.netValue
  })

  const paymentFlags = computed(() => {
    return store.state.checkout.paymentFlags
  })

  const isInternational = computed(() => {
    const isInternational = store.state.checkout.isInternational
    return isInternational
  })

  const resetCoupon = () => {
    store.commit('checkout/updateDiscount', null)
    updateCheckoutFormData({coupon: ''})
    couponErrorMessage.value = ''
  }

  return {
    fetchCheckoutInfo,
    submitCheckoutData,
    saveLead,
    researchUserProfiles,
    isLoaded,
    installments,
    products,
    paymentMethods,
    total,
    paymentFlags,
    checkoutFormData,
    checkoutCode,
    useMonthlyValue,
    monthlyValue,
    isInternational,
    updateCheckoutFormData,
    submitCheckoutStatus,
    researchCoupon,
    isResearchCoupon,
    discount,
    resetCoupon,
    couponErrorMessage,
    currencyCode
  }
}