import { useAxios } from "@/composables/useAxios"

type ResearchUserProfilesOutput = {
  id: string
  name: string
  subscription?: {
    _active: boolean
    identifier: string,
		interval: string
  }
}

export class UserServices {
  static async researchUserProfiles(email: string): Promise<ResearchUserProfilesOutput[]> {
    const { httpFunctions } = await useAxios()

    try {
      const { data: profiles } = await httpFunctions.get<ResearchUserProfilesOutput[]>(`/user-requests/research/${encodeURIComponent(email)}`)
      return Promise.resolve(profiles)
    } catch (error) {
      return Promise.reject()
    }
  }
}