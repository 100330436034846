<template>
  <div
    class="flex flex-col items-start justify-between w-full space-y-4 text-sm mb-12 md:mb-0"
    v-if="isLoaded"
  >
    <div
      v-for="(product, index) in products" :key="index"
      class="flex md:space-x-2 w-full"
    >
      <div class="hidden md:block">
        <i class="fas fa-shopping-cart text-gray-500"></i>
      </div>
      <div class="flex flex-col md:flex-row items-center md:items-start md:justify-between w-full md:space-x-6 space-y-4 md:space-y-0">
        <div class="flex flex-col items-center md:items-start w-full">
          <div class="text-lg md:text-base">
            {{product.name}}
          </div>
          <small class="text-gray-500 text-sm md:text-xs">{{product.description}}</small>
        </div>
        <div
          class="flex flex-col items-center md:items-end"
          v-if="useMonthlyValue"
        >
          <div class=" flex text-lg md:text-base font-semibold whitespace-nowrap">
            {{format(monthlyValue, '$0.00', currencyCode)}}
            <small class="hidden md:block ml-1">/ mês</small>
          </div>
          <small class="md:hidden">Por mês</small>
        </div>
        <div
          class="flex flex-col items-center md:items-end"
          v-else
        >
          <small class="md:hidden">Total</small>
          <div class="text-lg md:text-base font-semibold whitespace-nowrap">
            {{format(product.price, '$0.00', currencyCode)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useCheckout } from '@/composables/checkout/useCheckout'
import { useNumeral } from '@/composables/useNumeral'
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    const {
      isLoaded,
      products,
      useMonthlyValue,
      monthlyValue,
      currencyCode
    } = useCheckout()

    const { format } = useNumeral()
    
    return {
      isLoaded,
      products,
      useMonthlyValue,
      monthlyValue,
      currencyCode,
      format
    }
  }
})
</script>
