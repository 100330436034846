
import BaseInput from '@/components/base/BaseInput.vue'
import { useCheckout } from '@/composables/checkout/useCheckout'
import { useAuth } from '@/composables/firebase/useAuth'
import { computed, defineComponent, onMounted, reactive, toRaw, watchEffect } from 'vue'
import PurchaseSummary from '@/components/pages/checkout/PurchaseSummary.vue'
import { fbPixel } from '@/plugins/facebook/pixel'

export default defineComponent({
  components: { BaseInput, PurchaseSummary },
  setup () {
    const { sendPasswordResetEmail } = useAuth()

    const {
      isLoaded: isCheckoutDataLoaded,
      products,
      checkoutFormData,
      submitCheckoutStatus,
    } = useCheckout()
    
    const form = reactive({
      email: ''
    })

    const isBoleto = computed(() => {
      const paymentMethod = submitCheckoutStatus.value.data?.paymentMethod
      return paymentMethod === 'boleto'
    })

    const attemptRecoverPassword = () => {
      const { email } = toRaw(form)
      sendPasswordResetEmail(email)
    }
    
    watchEffect(() => {
      if (isCheckoutDataLoaded.value) {
        products.value.map((product: {id: string, name: string, price: number}) => {
          const payload = {
            content_type: 'product',
            content_name: product.name,
            content_ids: [product.id],
            value: product.price,
            currency: 'BRL'
          }
          fbPixel('track', 'Purchase', payload)
          fbPixel('trackSingleCustom','796704134043684', 'COMPROUMANY')
          fbPixel('trackSingleCustom','768749882041585', 'COMPROUMANY2')
        })
      }
    })

    onMounted(() => {
      fbPixel('track', 'PageView')
    })

    return {
      attemptRecoverPassword,
      form,
      isCheckoutDataLoaded,
      products,
      checkoutFormData,
      isBoleto
    }
  }
})
